import { lib_encrypt } from "../libs/lib.encrypt";

//	E X P O R T   L I B

export class funcs_encrypt
{

//#region 																							B A S E   3 2   E N C R Y P T

	base32___encrypt(___input: string) : string
	{
		
		const ___base32___charset: string = lib_encrypt.base32.charset;
		const ___base32___input___bytes: Uint8Array = new TextEncoder().encode(___input);
		
		let ___encoded: string = '';
		let ___encoded___padding: string = '';

		let ___bits: number = 0;
		let ___value: number = 0;
		
		for (let i = 0; i < ___base32___input___bytes.length; i++) {
			___value = (___value << 8) | ___base32___input___bytes[i];
			___bits += 8;
			while (___bits >= 5) {
				___encoded += ___base32___charset[(___value >>> (___bits - 5)) & 31];
				___bits -= 5;
			}
		}

		if (___bits > 0) {
			___encoded += ___base32___charset[(___value << (5 - ___bits)) & 31];
			___encoded___padding = lib_encrypt.base32.padding.repeat((8 - (___encoded.length % 8)) % 8);
		}

		return ___encoded + ___encoded___padding;

	}

//#endregion

//#region 																							B A S E   3 2    D E C R Y P T

	base32___decrypt(___input: string) : string
	{
	
		const ___base32___charset: string = lib_encrypt.base32.charset;
		const ___base32___lookup: { [key: string]: number } = {};

		for (let i = 0; i < ___base32___charset.length; i++) {
			___base32___lookup[___base32___charset[i]] = i;
		}
		const ___input___no_padding: string = ___input.replace(lib_encrypt.base32.padding, '');

		let ___bits: number = 0;
		let ___value: number = 0;
		const ___decoded___bytes: number[] = [];

		for (let i = 0; i < ___input___no_padding.length; i++) {
			___value = (___value << 5) | ___base32___lookup[___input___no_padding[i]];
			___bits += 5;
			if (___bits >= 8) {
				___decoded___bytes.push((___value >>> (___bits - 8)) & 255);
				___bits -= 8;
			}
		}

		const ___decoded: string = new TextDecoder().decode(new Uint8Array(___decoded___bytes));
		const ___decoded___unwhitespaced: string = ___decoded.replace(/[^a-zA-Z0-9,:{}_"]/g, '');

		return ___decoded___unwhitespaced;

	}

//#endregion

}