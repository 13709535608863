import React from 'react';

//	S T Y L E S

import './sb-view-event.scss';

//	S T A T E   -   P R O P S

import { props___SbViewEventPage, state___SbViewEventPage, state___SbViewEventPage___default } from './sb-view-event.state';

//	T Y P E S

import { type___api___response } from '../../types/type.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventTextComponent from '../../components/com-event-text/com-event-text';
import ComEventTimeScheduleComponent from '../../components/com-event-timeschedule/com-event-timeschedule';
import ComGuiBackButtonComponent from '../../components/com-button-back/com-button-back';
import ComMapPreviewComponent from '../../components/com-map-preview/com-map-preview';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class SbViewEventPage extends React.Component<props___SbViewEventPage, state___SbViewEventPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbViewEventPage,
	) {
		super(props);
		this.state = state___SbViewEventPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___temp___event_props: type___api___response = await this._API.qr___read___invite_event(this.state.event___id!);
		if (___temp___event_props.response !== 'success') { window.location.href = '/404'; }
		this.setState({
			event___props: ___temp___event_props.data,
			event___props___is_loading: false,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		if (this.props && this.props.params && this.props.params.id) {
			this.setState({
				event___id: this.props.params.id,
			}, async () => {
				await this.read_props___event();
			});
		} else {
			window.location.href = '/404';
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<ComGuiBackButtonComponent/>
			
			{(this.state.event___props___is_loading === true) ? <>
				
				<ComSpinnerComponent/>
			
			</> : <>
				
				<div className="sb-view-event---container">

					<br />

					<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
					
					<br />

					<div className="sb-view-event---image">
						{(this.state.event___props['event_poster'] && this.state.event___props['event_poster'].length > 0) ? <>
							<img src={ this.state.event___props['event_poster'] } />
						</> : <>
							<img src={ '/assets/markers/event/' + this.state.event___props['event_category']['event_category_icon'] + '.svg' } />
						</>}
					</div>

					<br />
					<br />

					<h1>{ this.state.event___props['event_name'] }</h1>

					<br />

					<ComEventTextComponent text={ this.state.event___props['event_description'] }/>

					<br />
					<br />
					<br />

					<ComMapPreviewComponent coords___lng={ this.state.event___props['event_coords_lng'] } coords___lat={ this.state.event___props['event_coords_lat'] } />

					<br />
					<br />
					<br />

				</div>

			</>}

		</>;

	}

//#endregion

}