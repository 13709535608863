import { type___params__url } from "../../types/types.params";

//	P R O P S

export interface props___SbViewEventPage {
	params: type___params__url,
}

//	S T A T E

export interface state___SbViewEventPage {

	event___id: string | null,
	event___props: any | null,
	event___props___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbViewEventPage___default: state___SbViewEventPage = {

	event___id: null,
	event___props: null,
	event___props___is_loading: true,

}