import React from 'react'

//	S T Y L E

import './com-button-back.scss'

//	C L A S S

export default class ComGuiBackButtonComponent extends React.Component<{}, {}>
{

//#region 																							H A N D L E R S

	handle___go_back = () => {
		window.history.back()
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{

		return <>
			<div className="com-button-back---container">
				<div onClick={ this.handle___go_back }>
					<i className="fa-solid fa-chevron-left"></i>
					<span>Indietro</span>
				</div>
			</div>
		</>;

	}

//#endregion

}