import React from 'react';

//	S T Y L E S

import './sb-view-invite.scss';

//	S T A T E   -   P R O P S

import { props___SbViewInvitePage, state___SbViewInvitePage, state___SbViewInvitePage___default } from './sb-view-invite.state';

//	T Y P E S

import { type___api___response } from '../../types/type.api-response';

//	F U N C S

import { funcs_tickets } from '../../funcs/funcs.tickets';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComButtonViewEventComponent from '../../components/com-button-view-event/com-button-view-event';
import ComEventTimeScheduleComponent from '../../components/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComQrViewComponent from '../../components/com-qr-view/com-qr-view';

//	C L A S S

export default class SbViewInvitePage extends React.Component<props___SbViewInvitePage, state___SbViewInvitePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___tickets: funcs_tickets = new funcs_tickets();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbViewInvitePage,
	) {
		super(props);
		this.state = state___SbViewInvitePage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___temp___event_props: type___api___response = await this._API.qr___read___invite_event(this.state.invite_event___id!);


		console.log('___temp___event_props', ___temp___event_props)


	//	if (___temp___event_props.response !== 'success') { window.location.href = '/404'; }
		this.setState({
			invite_event___props: ___temp___event_props.data,
			invite_event___props___is_loading: false,
		});
	}

	read_props___pr = async () => {
		const ___temp___event_props: type___api___response = await this._API.qr___read___invite_pr(this.state.invite_event___id!, this.state.invite_pr___id!);
		this.setState({
			invite_pr___props: ___temp___event_props.data,
			invite_pr___props___is_loading: false,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {



		console.log('this.props', this.props)

		console.log('this.props', this.props)



		if (this.props && this.props.params && this.props.params.id) {


			console.log('this.props', this.props)



			this.setState({
				invite___props: this.props.params.id,
				invite___props___decoded: this.funcs___tickets.ticket___decrypt___to_object(this.props.params.id)
			}, () => {
				this.setState({
					invite_event___id: (this.state.invite___props___decoded && this.state.invite___props___decoded.ticket_event) ? this.state.invite___props___decoded.ticket_event : null, 
					invite_pr___id: (this.state.invite___props___decoded && this.state.invite___props___decoded.ticket_pr) ? this.state.invite___props___decoded.ticket_pr : null, 
				}, async () => {
					await this.read_props___event();
					await this.read_props___pr();
				});
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="sb-page---container---standard">
				<section>
					{(this.state.invite_event___props___is_loading === true) ? <>
						<br />
						<br />
						<ComSpinnerComponent/>
						<br />
						<br />
					</> : <>
						<div className="sb-view-invite---container">
							<ComEventTimeScheduleComponent event___props={ this.state.invite_event___props }/>
							<ComQrViewComponent qrCode_content={ this.state.invite___props }/>
							<h1>{ this.state.invite_event___props['event_name'] }</h1>
							{(this.state.invite_pr___props___is_loading === true) ? <>
								<br />
								<br />
								loading
							</> : <>
								{(this.state.invite_pr___props !== null) ? <>
									<br />
									<br />
									<h6>Invitato da</h6>
									{(this.state.invite_pr___props['user_name_displayed'] && this.state.invite_pr___props['user_name_displayed'].length > 0) ? <>
										<h5>{ this.state.invite_pr___props['user_name_displayed'] }</h5>
									</> : <>
										<h5>{ this.state.invite_pr___props['user_name'] + ' ' + this.state.invite_pr___props['user_surname'] }</h5>
									</>}
								</> : <></>}
							</>}
							<br />
							<br />
							<ComButtonViewEventComponent event_id={ this.state.invite_event___id! } />
						</div>
					</>}
				</section>
			</div>
		</>;
	}

//#endregion

}