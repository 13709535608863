import { funcs_numbers } from "./funcs.numbers";

//	E X P O R T   L I B

export class funcs_datetime
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							D A T E T I M E   G E T   D A T E

	datetime___get___date(___datetime_string: Date | string) : string
	{

		const ___datetime_obj: Date = new Date(___datetime_string);
		
		const ___d___day: string = ___datetime_obj.getDate().toString().padStart(2, '0');
		const ___d___month: string = (___datetime_obj.getMonth() + 1).toString().padStart(2, '0');
		const ___d___year: string = ___datetime_obj.getFullYear().toString();
		
		return ___d___day.toString() + '/' + ___d___month.toString() + '/' + ___d___year.toString();

	}

//#endregion

//#region 																							D A T E T I M E   G E T   T I M E

	datetime___get___time(___datetime_string: Date | string) : string
	{

		const ___datetime_obj: Date = new Date(___datetime_string);
		
		const ___d___hours: string = ___datetime_obj.getHours().toString().padStart(2, '0');
		const ___d___minutes: string = ___datetime_obj.getMinutes().toString().padStart(2, '0');
		
		return ___d___hours.toString() + ':' + ___d___minutes.toString();

	}

//#endregion

}