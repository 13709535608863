//	E X P O R T   L I B

export class funcs_numbers
{

//#region 																							F O R M A T   N  U M B E R

	private format___number(___value: number, ___decimal: number) : string
	{
		const ___value___formatter: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: ___decimal,
			maximumFractionDigits: ___decimal,
		});
		return ___value___formatter.format(___value) as string;
	}

//#endregion

//#region 																							F O R M A T   P R I C E

	public format___price(___value: string | number, ___symbol: string | null = '€') : string
	{
		const ___parsed_price: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_price) === true) { return '0'; }
		const ___parsed_price___formatted: string = this.format___number(___parsed_price, 2);
		return ___parsed_price___formatted as string + ' ' + ((___symbol !== null) ? ___symbol : '');
	}

//#endregion

//#region 																							P A D

	public pad___twoDigits(___value: number) : string
	{
		const ___value___to_return: string =  ___value.toString().padStart(2, '0');
		return ___value___to_return;
	}

//#endregion

}