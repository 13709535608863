import React from 'react'

//	S T Y L E

import './com-spinner.scss'

//	C L A S S

export default class ComSpinnerComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render(): React.ReactNode
	{

		return <>
			<div className="com-spinner---container">
				<div>
					<img className="com-spinner---inner" src="/assets/spinner/inner.svg"/>
					<img className="com-spinner---outer" src="/assets/spinner/outer.svg"/>
				</div>
			</div>
		</>;

	}

//#endregion

}