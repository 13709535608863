import React from 'react'

//	S T Y L E

import './com-copyright.scss'

//	C L A S S

export default class ComCopyrightComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render(): React.ReactNode
	{

		return <>
			<div className="com-copyright---container">
				<img src="/assets/logo-horizontal.svg"/>
				<p>Powered by <a target="_blank" href="http://www.nopr.me/">NoPR</a> • Tutti i Diritti Riservati • <a target="_blank" href="http://www.nopr.me/privacy-policy">Privacy Policy</a></p>
			</div>
		</>;

	}

//#endregion

}