import React from 'react'
import MapBoxGL from 'mapbox-gl'

//	S T Y L E

import 'mapbox-gl/dist/mapbox-gl.css';
import './com-map-preview.scss';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___ComMapPreviewComponent, state___ComMapPreviewComponent, state___ComMapPreviewComponent___default } from './com-map-preview.state';

//	C L A S S

export default class ComMapPreviewComponent extends React.Component<props___ComMapPreviewComponent, state___ComMapPreviewComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly mapbox___map___container: React.RefObject<HTMLDivElement> = React.createRef();
	private mapbox___map___loaded: boolean = false;
	private mapbox___map!: MapBoxGL.Map;


//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComMapPreviewComponent,
	)
	{
		super(props);
		this.state = state___ComMapPreviewComponent___default;
	}

//#endregion

//#region 																							M A P

	map___prepare = () => {
		if (this.mapbox___map___loaded !== true)
		{

			this.mapbox___map = new MapBoxGL.Map({
				accessToken: lib_names.MapBoxGL.accessToken,
				container: this.mapbox___map___container.current as HTMLElement,
				style: lib_names.MapBoxGL.style,
				center: [this.props.coords___lng, this.props.coords___lat],
				zoom: lib_names.MapBoxGL.zoomPreview,
			});

			const ___mapbox___map___marker: MapBoxGL.Marker = new MapBoxGL.Marker({ color: '#fd4755' });
			___mapbox___map___marker
				.setLngLat({ lng: this.props.coords___lng, lat: this.props.coords___lat })
				.addTo(this.mapbox___map);

			this.mapbox___map.resize();
			this.mapbox___map___loaded = true;

		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___open_map_route = () => {
		const ___url___android: string = 'https://www.google.com/maps?q=' + this.props.coords___lat.toString() + ',' + this.props.coords___lng.toString();
		window.open(___url___android);
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		this.map___prepare();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className="com-map-preview---component" onClick={ this.handle___open_map_route }>
				<div>
					<div ref={ this.mapbox___map___container } className="com-map-preview---map"/>
				</div>
			</div>
		</>;

	}

//#endregion

}