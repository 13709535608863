import React from 'react'
import { Link } from 'react-router-dom';

//	S T Y L E

import './com-button-view-event.scss'

//	S T A T E S   -   P R O P S

import { props___ComButtonViewEventComponent } from './com-button-view-event.state';

//	C L A S S

export default class ComButtonViewEventComponent extends React.Component<props___ComButtonViewEventComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComButtonViewEventComponent,
	)
	{
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<Link className="com-button-view-event---component" to={ '/view/event/' + this.props.event_id }>
				<span>vedi l'evento</span>
				<i className="fa-solid fa-chevron-right"></i>
			</Link>
		</>;

	}

//#endregion

}