import React from 'react';
import { NavigateFunction, Params, useNavigate, useParams } from 'react-router-dom';

//	Interface for the props

export interface PropsAppNavigationWrapper<T> {
	component: React.ComponentType<T>;
}

//	Wrapper Component

const AppNavigationWrapper: React.FC<PropsAppNavigationWrapper<any>> = ({ component: WrappedComponent }) => {
	const params: Readonly<Params<string>> = useParams();
	const navigate: NavigateFunction = useNavigate();
	return <WrappedComponent params={params} navigate={navigate} />;
};

//	Export the Wrapper

export default AppNavigationWrapper;