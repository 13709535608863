import React from 'react'

//	S T Y L E

import './com-event-timeschedule.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventComponent } from './com-event-timeschedule.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	C L A S S

export default class ComEventTimeScheduleComponent extends React.Component<props___ComEventComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-event-timeschedule---container">
			
				{(this.props.event___props['event_datetime_is_now'] === true) ? <>
					<div className="com-event-timeschedule---is-now"></div>
				</> : <>
					<i className="far fa-calendar-alt"></i>
				</>}
			
				<span></span>
			
				<section>
					{(this.props.event___props['event_ended'] === false) ? <>
						{(this.props.event___props['event_is_now'] === true) ? <>
							<p><span style={{color:'#fd4755'}}><span style={{fontSize:'large'}}>In corso</span></span></p>
						</> : <>
							<p><span><span>Save the date!</span></span></p>
							<p><span><span>Inizia</span> il </span><span><span>{ this.funcs___datetime.datetime___get___date(this.props.event___props['event_datetime_begin']) }</span> alle <span>{ this.funcs___datetime.datetime___get___time(this.props.event___props['event_datetime_begin']) }</span></span></p>
						</>}
					</> : <>
						<p><span><span>Evento Terminato</span></span></p>
						<p><span><span>Terminato</span> il </span><span><span>{ this.funcs___datetime.datetime___get___date(this.props.event___props['event_datetime_end']) }</span> alle <span>{ this.funcs___datetime.datetime___get___time(this.props.event___props['event_datetime_end']) }</span></span></p>
					</>}
				</section>

			</section>
		</>;
	}

//#endregion

}