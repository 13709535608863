//	L I B S

import { lib_names } from '../libs/lib.names';

//	T Y P E S

import { type___api___response } from '../types/type.api-response';

//	E X P O R T

export class service_RestApiService
{

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___target: string, ___to_post: any | {} = {}) : Promise<any>
	{
		const ___s_route: string = lib_names.endpoints.server_api + 'qr' + '/' + ___target;
		try {
			const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
			if (___s_response.ok) {
				try {
					const ___server_response: any = await ___s_response.json();
					return (___server_response !== null && ___server_response !== undefined) ? ___server_response : null;
				} catch (___e: any) {
					return null;
				}
			} else {
				return null;
			}
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return null;
		}
	}

//#endregion

//#region 																							I N V I T E

	async qr___read___invite_event(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id };
		return await this.return_server_response('invite/event', ___to_post)
	}

	async qr___read___invite_pr(___event_id: string, ___pr_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'pr_id': ___pr_id };
		return await this.return_server_response('invite/pr', ___to_post)
	}

//#endregion



































//#region 																							P R E L O A D

	async read_props___ticket(___ticket_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'ticket_id': ___ticket_id };
		return await this.return_server_response('ticket', ___to_post)
	}

//#endregion


















}