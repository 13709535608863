import { type___params__url } from "../../types/types.params"
import { type___ticket___object } from "../../types/types.types"

//	P R O P S

export interface props___SbViewInvitePage {
	
	params: type___params__url,

}

//	S T A T E

export interface state___SbViewInvitePage {

	invite___props: string,
	invite___props___decoded: type___ticket___object | null,

	invite_event___id: string | null,
	invite_event___props: any | null,
	invite_event___props___is_loading: boolean,

	invite_pr___id: string | null,
	invite_pr___props: any | null,
	invite_pr___props___is_loading: boolean,















}

//	S T A T E   D E F A U L T S

export const state___SbViewInvitePage___default: state___SbViewInvitePage = {

	invite___props: '',
	invite___props___decoded: null,

	invite_event___id: null,
	invite_event___props: null,
	invite_event___props___is_loading: true,

	invite_pr___id: null,
	invite_pr___props: null,
	invite_pr___props___is_loading: true,
















}