//	P R O P S

export interface props___ComMapPreviewComponent {

	coords___lng: number,
	coords___lat: number,

	canOpenRoute?: boolean,

}

//	S T A T E

export interface state___ComMapPreviewComponent {

	GUI___actionSheet___map_route_to: boolean

}

//	S T A T E   D E F A U L T

export const state___ComMapPreviewComponent___default: state___ComMapPreviewComponent = {

	GUI___actionSheet___map_route_to: false

}