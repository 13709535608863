import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

//	S T Y L E S

import './theme/reset.css';
import './theme/global.scss';

//	W R A P P E R S

import AppNavigationWrapper from './App.NavigationWrapper';

//	C O M P O N E N T S

import ComCopyrightComponent from './components/com-copyright/com-copyright';

//	P A G E S

import SbViewEventPage from './pages/sb-view-event/sb-view-event';
import SbViewInvitePage from './pages/sb-view-invite/sb-view-invite';

//	C L A S S



//					http://localhost:3001/invite/PMRHI2LDNNSXIX3UPFYGKIR2EJUW45TJORSSELBCORUWG23FORPWSZBCHJXHK3DMFQRHI2LDNNSXIX3FOZSW45BCHIRDMNTEMVRWGOJTGM3TCYLGMMZDMMRYGMZTGOLFGARCYITUNFRWWZLUL5YHEIR2EI3DKNJSGE2GMZDEGE2TSOJUGBSTENJSMM2DCMBQEJ6Q8888

export default class App extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
		
			<Router>
				<Routes>

					<Route path="/invite/:id" element={<AppNavigationWrapper component={ SbViewInvitePage }/>} />
					<Route path="/ticket/:id" element={<></>} />
					
					<Route path="/view/event/:id" element={<AppNavigationWrapper component={ SbViewEventPage }/>} />
					
					<Route path="404" element={<></>} />
					<Route index element={<Navigate replace to="/404" />} />

				</Routes>
			</Router>

			<ComCopyrightComponent/>

		</>;

	}

//#endregion

}